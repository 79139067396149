export default {
	data() {
		return {
			hotelItems: {
				deploy: {
					row: [
						{
							subassembly: 'strong',
							default: '酒店预订',
							cols: 12
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'hotelcode',
							connector: {
								props: {
									label: "酒店"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'rmtype',
							connector: {
								props: {
									label: "房型"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'anz',
							connector: {
								props: {
									label: "数量"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'arrdate',
							connector: {
								props: {
									label: "入住日期"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'deptdate',
							connector: {
								props: {
									label: "离店日期"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "总价"
								}
							}
						},
						{
							cols: 12,
							subassembly: "VTextField",
							property: 'memo',
							connector: {
								props: {
									label: "备注"
								}
							}
						},
					]
				}
			}
		}
	}
}
