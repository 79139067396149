export default {
	data() {
		return {
			ticketItems: {
				deploy: {
					row: [
						{
							subassembly: 'strong',
							default: '票务预订',
							cols: 12
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'groupid',
							connector: {
								props: {
									label: "票务分组"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'tcode',
							connector: {
								props: {
									label: "票型"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'anz',
							connector: {
								props: {
									label: "数量"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'usedate',
							connector: {
								props: {
									label: "游玩日期"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "总价"
								}
							}
						},
						{
							cols: 12,
							subassembly: "VTextField",
							property: 'memo',
							connector: {
								props: {
									label: "备注"
								}
							}
						},
					]
				}
			}
		}
	}
}
