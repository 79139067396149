<template>
	<div>
		<common-table
				:itemScope="['mainstatus']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				ref="ct"
				pageTitle="历史订单"
				:customHtml="true"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:mainstatus="{ item }">
				<!-- 格式化支付状态 -->
				<formatter-field :status="item.mainstatus" field="payStatus"/>
			</template>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="fixedConfig"
						:othersConfig="othersConfig"
						@search="search"
						:extraParams="extraParams"
						@advancedSearch="advancedSearch"
				/>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box
					:contentHeight="500"
					:width="936"
					:dialog.sync="dialog"
					usageMode="tabs"
					:items="items">
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/history-order.js';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsHotel from '../common-order-js/details-hotel.js';
import detailsOrder from '../common-order-js/details-order.js';
import detailsTicket from '../common-order-js/details-ticket.js';
import detailsCombo from '../common-order-js/details-combo.js';
import detailsLog from '../common-order-js/details-log.js';
import orderDetailsTabs from '../common-order-js/order-history-details-tabs';
import formatterField from '../formatter.vue';

export default {
	// 订单记录
	name: "order-record",
	components: {advancedSearch, exhibitionDataBox, formatterField},
	mixins: [commonCurd, detailsHotel, detailsOrder, detailsLog, detailsTicket, detailsCombo, orderDetailsTabs],
	data() {
		return {
			api,
			// 是否展示订单
			showOrder: false,
			extraParams: '',
			tempItem: null,
			dialog: false,
			// 设定一个订单号
			bookingid: '',
			// 操作按钮状态
			items: [],
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '详情'
				}
			],
			headers: [
				{text: '订单号/综合预订号', value: 'bookingid'},
				{text: '预订人姓名', value: 'bookername'},
				{text: '预订人电话', value: 'tel'},
				{text: '订单金额', value: 'amount'},
				{text: '订单状态', value: 'mainstatus'},
				{text: '支付方式', value: 'payment'},
				{text: '商品类型', value: 'ptypedesc'},
				{text: '订单来源', value: 'agenttypedesc'},
				{text: '创建时间', value: 'createdate'},
				{text: '操作', value: 'actions', width: '60px'}
			],
			fixedConfig: [
				{type: 'input', label: '输入订单号/综合预订号', property: 'bookingid', width: '180px'},
				{type: 'input', label: '支付流水号', property: 'payid'}
			],
			othersConfig: [
				{type: 'input', label: '预订人电话', property: 'tel'}
			]
		}
	},
	methods: {
		advancedSearch() {
			this.$nextTick(() => {
				this.$refs.ct.getTableHeight();
			});
		},
		otherTableOperate(e, item) {
			this.tempItem = item;
			this.bookingid = item.bookingid
			this.showOrder = !item.lhide
			this.getOrderDetailsTabs(item, true, true, 'orderRecord');
		}
	},
	created() {
		// 外部跳转的额外参数
		this.extraParams = this.$route.query.value;
	}
}
</script>

<style scoped lang="scss">

</style>
