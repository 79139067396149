export default {
	data() {
		return {
			orderItems: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookingid',
							connector: {
								props: {
									label: "订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'outid',
							connector: {
								props: {
									label: "预订平台订单编号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'mainstatusdesc',
							connector: {
								props: {
									label: "订单状态",
									readonly: true
								}
							}
						},
						/*{
							cols: 4,
							subassembly: "VTextField",
							property: 'memberid',
							connector: {
								props: {
									label: "会员编号",
									readonly: true
								}
							}
						},*/
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookername',
							connector: {
								props: {
									label: "预订人姓名",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'tel',
							connector: {
								props: {
									label: "预订人手机号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "商品总金额",
									readonly: true
								}
							}
						},
						/*{
							cols: 4,
							subassembly: "VTextField",
							property: '',
							connector: {
								props: {
									label: "已优惠金额",
									readonly: true
								}
							}
						},*/
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payamount',
							connector: {
								props: {
									label: "实际支付金额",
									readonly: true
								}
							}
						},
						/*{
							cols: 4,
							subassembly: "VTextField",
							property: '',
							connector: {
								props: {
									label: "优惠券ID",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: '',
							connector: {
								props: {
									label: "优惠券编码",
									readonly: true
								}
							}
						},*/
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payment',
							connector: {
								props: {
									label: "支付方式",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'paytime',
							connector: {
								props: {
									label: "支付时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payno',
							connector: {
								props: {
									label: "支付订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payid',
							connector: {
								props: {
									label: "支付流水号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'createdate',
							connector: {
								props: {
									label: "创建时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'reason',
							connector: {
								props: {
									label: "退款原因",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refund',
							connector: {
								props: {
									label: "退款金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refundtime',
							connector: {
								props: {
									label: "退款时间",
									readonly: true
								}
							}
						},
						{
							cols: 12,
							subassembly: "VTextField",
							property: 'memo',
							connector: {
								props: {
									label: "订单备注",
									readonly: true
								}
							}
						},
						{
							cols: 12,
							subassembly: "VTextField",
							property: 'umemo',
							connector: {
								props: {
									label: "用户备注",
									readonly: true
								}
							}
						}
					]
				}
			}
		}
	}
}
